import React, { Fragment, useState } from 'react';
import { FormattedNumber, FormattedMessage } from 'react-intl';

import DataRow from 'components/Shared/DataRow/DataRow';
import FormattedDateNoTimeZone from 'components/Shared/FormattedDateNoTimeZone/FormattedDateNoTimeZone';
import InvoiceStatus from 'components/Shared/InvoiceStatus/InvoiceStatus';
import CpPopover from 'components/Shared/Popover/CpPopover';
import { useIsMobileScreen } from 'hooks/useIsMobile';
import { breakpoints } from 'styles/cp';
export type InvoiceInfoProps = {
  invoiceNumber?: string;
  invoiceDueDate?: string;
  invoiceAmount: number;
  currency?: string;
  featureFlags?: object;
  useRedesign: boolean;
  showModal?: any;
  pdfUrl?: string;
  fetchPDFStatus?: string;
  token?: string;
  gratuityValue: number;
  isPartiallyPaid?: boolean;
  feeAmount?: number;
};
const InvoiceInfo = (props: InvoiceInfoProps) => {
  const {
    gratuityValue,
    invoiceNumber,
    invoiceDueDate,
    invoiceAmount,
    currency,
    isPartiallyPaid,
    feeAmount,
    useRedesign,
  } = props;
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const isMobile = useIsMobileScreen(840);

  return (
    <Fragment>
      <style jsx>{`
        .invoice-info {
          padding: 0px;
          margin: ${useRedesign ? '0 14px' : '0px'};

          @media (max-width: ${breakpoints.md}) {
            max-width: 400px;
            margin: 5px auto;
          }
          @media (max-width: ${breakpoints.sm}) {
            max-width: 576px;
          }
        }
      `}</style>

      <ul className="invoice-info" aria-label="invoice info list">
        {invoiceNumber && (
          <DataRow
            idValue="INVOICE_SUMMARY_INVOICE"
            defaultMessage="Invoice"
            value={invoiceNumber}
          />
        )}
        {invoiceDueDate && (
          <DataRow
            idValue="INVOICE_SUMMARY_DUE_DATE"
            defaultMessage="Due Date"
            value={<FormattedDateNoTimeZone value={invoiceDueDate} />}
          />
        )}
        {invoiceAmount >= 0 && (
          <DataRow
            idValue="INVOICE_SUMMARY_INVOICE_AMOUNT"
            defaultMessage="Invoice amount"
            value={<FormattedNumber value={invoiceAmount} style="currency" currency={currency} />}
          />
        )}
        {gratuityValue > 0 && (
          <DataRow
            idValue="INVOICE_SUMMARY_INVOICE_TIP"
            defaultMessage="Tip"
            value={<FormattedNumber value={gratuityValue} style="currency" currency={currency} />}
          />
        )}
        {isPartiallyPaid && <InvoiceStatus idValue="INVOICE_SUMMARY_PARTIALLY_PAID" />}
        {feeAmount === undefined || feeAmount === 0 ? null : (
          <span
            id="maip-tooltip-w"
            onMouseEnter={() => setIsTooltipOpen(true)}
            onMouseLeave={() => setIsTooltipOpen(false)}
          >
            <DataRow
              borderBottom="dotted"
              idValue="MAIP_ONLINE_CONVENIENCE_FEE"
              defaultMessage="Online convenience fee"
              label={
                <span
                  style={{
                    marginLeft: '6px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                ></span>
              }
              value={<FormattedNumber value={feeAmount} style="currency" currency={currency} />}
            />
          </span>
        )}
        <CpPopover
          className="cp-tooltip-wrapper"
          innerClassName="cp-tooltip"
          placement={isMobile ? 'bottom' : 'top-end'}
          target={isMobile ? 'invoice-more-info' : 'maip-tooltip-w'}
          isOpen={isTooltipOpen}
        >
          <FormattedMessage
            id="MAIP_TOOLTIP_MSG"
            defaultMessage="This is just a fee for paying online. You can still pay offline without paying this fee."
          />
        </CpPopover>
      </ul>
    </Fragment>
  );
};
InvoiceInfo.defaultProps = {
  gratuityValue: 0,
};
export default InvoiceInfo;
