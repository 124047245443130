import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TXN_MAP } from 'types/constants';

const ViewInvoiceMessage = ({
  fetchPDFStatus = TXN_MAP.STATUS.IN_PROGRESS,
  userClicked = false,
}) => {
  if (fetchPDFStatus === null || !userClicked) {
    return <FormattedMessage id="PRINT_AND_SAVE_VIEW_INVOICE" defaultMessage="View invoice" />;
  }
  return {
    [TXN_MAP.STATUS.SUCCESS]: (
      <FormattedMessage id="PRINT_AND_SAVE_VIEW_INVOICE" defaultMessage="View invoice" />
    ),
    [TXN_MAP.STATUS.ERROR]: (
      <FormattedMessage
        id="PRINT_AND_SAVE_VIEW_INVOICE_ERROR"
        defaultMessage="Invoice unavailable"
      />
    ),
    [TXN_MAP.STATUS.IN_PROGRESS]: (
      <FormattedMessage id="PRINT_AND_SAVE_VIEW_INVOICE_LOADING" defaultMessage="Loading invoice" />
    ),
  }[fetchPDFStatus];
};
export default ViewInvoiceMessage;
