import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Chevron } from 'components/Shared/Icons/Icons';
import Separator from 'components/Shared/Separator/Separator';
import SegmentIO from 'reporting/SegmentIO';
import { colors, fontSize, breakpoints } from 'styles/cp';

export interface Props {
  children: React.ReactNode | string | null;
}

const MobileInvoicePreviewWrapper: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      <div className="more-details">
        <div
          className="header"
          onClick={() => {
            SegmentIO.clickShowOrHideDetails(!isOpen);
            setIsOpen(!isOpen);
          }}
        >
          <span className="view-invoice-label" id="invoice-more-info">
            <FormattedMessage
              id="VIEW_INVOICE_AND_MORE_INFO"
              defaultMessage="View invoice and more info"
            />
          </span>
          <Separator display="inline-block" width={5} />
          <Chevron width={12} height={16} color={colors.lightBlue} />
        </div>
        <div className="content-holder">
          <div className="content">{children}</div>
        </div>
      </div>
      <style jsx>{`
        .more-details {
          text-align: center;
          margin-top: 15px;
          display: none;

          @media screen and (max-width: ${breakpoints.md}) {
            display: block;
            margin-top: 0;
            background-color: ${colors.gray08};
          }

          .header {
            cursor: pointer;
            background-color: ${colors.white};
            padding-bottom: 4px;

            .view-invoice-label {
              color: ${colors.lightBlue};
              font-size: ${fontSize.xs};
              user-select: none;
              font-family: AvenirNextforINTUIT-Medium;
            }

            :global(svg) {
              transition: all 0.15s linear;
              transform: rotate(${isOpen ? 0 : 180}deg);
            }
          }

          .content-holder {
            max-height: ${isOpen ? '1000px' : 0};
            overflow: hidden;
            transition: max-height 0.35s ease;
            box-shadow: inset 0px 5px 20px 0px ${colors.black + '09'};

            .content {
              margin: 16px 40px 0;
            }

            a {
              text-decoration: inherit;
              color: inherit;
            }

            .flex-link {
              width: 100%;
              height: 100%;
            }
          }
        }
      `}</style>
    </Fragment>
  );
};

export default MobileInvoicePreviewWrapper;
